<template>
    <div class="pricingEngine">
      <div class="boxContent">
        <!-- 头部导航区域 -->
        <div class="navbar main-container">
          <Navbar :itemIndex="'8'" />
        </div>
      </div>
      <!-- banner区域 -->
      <div class="barnner-bg bg-pic">
        <div class="banner main-container">
          <div class="banner-title">
            定价引擎
          </div>
          <div class="see-more">
            在华炫鼎盛资金交易平台以及资金业务管理系统背后，都内嵌了强大的定价引擎，可以为多达1700多种本外币金融商品进行定价。无论在交易试算、风险分析、组合管理、会计核算方面，都应用了这套定价引擎。
          </div>
        </div>
      </div>
      
      <div class="proxy-trading-platform">
          <div class="proxy-trading-box">
            <div class="proxy-trading-center">
              <div class="benefit">定价引擎的特点</div>
              <ul>
                <li>
                  <img src="@/assets/pricingEngine/djyq_ico_1@2x.png" alt="" />
                  <div>涵盖全球绝大部分金融商品</div>
                  <span>华炫鼎盛定价引擎能为包含固定收益、利率商品、外汇商品、权益类商品、商品期货以及衍生商品等在内的1700多种金融商品进行定价；</span>
                </li>
                <li>
                  <img src="@/assets/pricingEngine/djyq_ico_2@2x.png" alt="" />
                  <div>完整的应用支持</div>
                  <span>华炫鼎盛定价引擎在外包覆了多种金融场景，可进行交易试算、风险分析、会计核算、监管报送、绩效考核等应用方面进行计算与分析；</span>
                </li>
                <li>
                  <img src="@/assets/pricingEngine/djyq_ico_3@2x.png" alt="" />
                  <div>投资组合分析</div>
                  <span>华炫鼎盛定价引擎不仅提供单一金融商品的定价与分析，还可以储存多个跨资产的投资组合，进行交易试算、风险分析、压力测试等组合类的计算，用户不需要一一处理单一金融商品的计算就能得到组合整体的分析数据；</span>
                </li>
                <li>
                  <img src="@/assets/pricingEngine/djyq_ico_4@2x.png" alt="" />
                  <div>具备扩充性</div>
                  <span>定价引擎自带分散式架构，可以适应银行多种的计算场景，在硬件条件许可下，可自动进行分布式运算、负载均衡管理，充分满足高频交易、代客交易、互联网交易等极端场景下的应用。</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="proxy-trading-img">
            <div class="proxy-img-title">定价引擎系统功能架构图</div>
            <div class="proxy-trading-bg"><img src="@/assets/pricingEngine/djyq_lct@2x.png" alt="" /></div>
          </div>
        </div>
      <!-- 底部通用 -->
      <div class="boxContentFooter">
        <FooterAll />
      </div>
    </div>
  </template>
  <script>
    import Navbar from '../../components/public/NavBar'
    import FooterAll from '../../components/public/footerAll'
    export default {
      components: {
        Navbar,
        FooterAll,
      },
      data(){
        return{
          tab0: true,
          tab1: false,
          tab2: false,
          dataArray1:[
              require('@/assets/partner/cooperate_zf_01@2x.png'),
              require('@/assets/partner/cooperate_zf_02@2x.png'),
              require('@/assets/partner/cooperate_zf_03@2x.png'),
              require('@/assets/partner/cooperate_zf_04@2x.png'),
              require('@/assets/partner/cooperate_zf_05@2x.png')
          ],
          dataArray2:[
            require('@/assets/partner/cooperate_yx_01@2x.png'),
            require('@/assets/partner/cooperate_yx_02@2x.png'),
          ],
        }
      },
      methods: {
      }
    }
  
  </script>
  <style lang="scss" scoped>
  
  @import "../partner/parter.scss";
  .pricingEngine {
    background: #FBFBFB;
  }
  .bg-pic{
    background: url('../../assets/pricingEngine/djyq_bj@2x.png')
    no-repeat ;
    background-size: 1920px 320px;
    background-position: 50%;
    min-width: 1180px;
  }
  ul,li{ padding:0;margin:0;list-style:none}
  
  .see-more{
    width: 796px;
    height: 75px;
    padding-top: 24px;
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    font-weight: 400;
  }
  .tabs-content {
    width: 1180px;
    margin: 0 auto;
  }
  .proxy-trading-platform {
    .proxy-trading-box {
      width: 1180px;
      margin: 0 auto;
    }
    .proxy-trading-center{
      width: 100%;
      height: 402px;
      .benefit {
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 28px;
        text-align: center;
        padding-top: 50px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        li {
          width: 277px;
          font-weight: 600;
          font-size: 20px;
          color: #0F4994;
          img {
            display: block;
            width: 50px;
            height: 50px;
            margin-bottom: 24px;
          }
          span {
            display: block;
            width: 277px;
            height: 96px;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }
    }
    .proxy-trading-img {
      width: 1519px;
      height: 1270px;
      margin: 0 auto;
      background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
      .proxy-img-title {
        padding-top: 68px;
        padding-bottom: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        text-align: center;
        margin: 0 auto;
      }
      .proxy-trading-bg {
        width: 1180px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 1085px;
        }
      }
    } 
  }
  </style>
  